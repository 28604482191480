export class Translations {
    static getLevelTitle(languageCode: string, isPoker: boolean, isSport: boolean) {
        let result: string;
        if (isPoker) {
            switch (languageCode) {
                case "eng": result = `Level <span class="lol-current-level lol-current-level-poker"></span>`; break;
                // case "ara": result = `المستوى <span class="lol-current-level"></span>`; break;
                case "bul": result = `Ниво <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "chi": result = `<span class="lol-current-level lol-current-level-poker"></span> 级`; break;
                case "cze": result = `Úroveň <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "dan": result = `Niveau <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "ger": result = `Stufe <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "gre": result = `Επίπεδο <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "spa": result = `Nivel <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "fin": result = `Taso <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "fre": result = `Niveau <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "hun": result = `<span class="lol-current-level lol-current-level-poker"></span>. szint`; break;
                case "ita": result = `Livello <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "jpn": result = `レベル <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "nor": result = `Nivå <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "dut": result = `Niveau <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "pol": result = `Poziom <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "por": result = `Nível <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "rum": result = `Nivel <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "rus": result = `Уровень <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "swe": result = `Nivå <span class="lol-current-level lol-current-level-poker"></span>`; break;
                case "tha": result = `ระดับ <span class="lol-current-level lol-current-level-poker"></span>`; break;
                default: result = `Level <span class="lol-current-level lol-current-level-poker"></span>`; break;
            }

            return result;
        }
        if (isSport) {
            switch (languageCode) {
                case "eng": result = `Level <span class="lol-current-level lol-current-level-sport"></span>`; break;
                // case "ara": result = `المستوى <span class="lol-current-level"></span>`; break;
                case "bul": result = `Ниво <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "chi": result = `<span class="lol-current-level lol-current-level-sport"></span> 级`; break;
                case "cze": result = `Úroveň <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "dan": result = `Niveau <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "ger": result = `Stufe <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "gre": result = `Επίπεδο <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "spa": result = `Nivel <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "fin": result = `Taso <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "fre": result = `Niveau <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "hun": result = `<span class="lol-current-level lol-current-level-sport"></span>. szint`; break;
                case "ita": result = `Livello <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "jpn": result = `レベル <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "nor": result = `Nivå <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "dut": result = `Niveau <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "pol": result = `Poziom <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "por": result = `Nível <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "rum": result = `Nivel <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "rus": result = `Уровень <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "swe": result = `Nivå <span class="lol-current-level lol-current-level-sport"></span>`; break;
                case "tha": result = `ระดับ <span class="lol-current-level lol-current-level-sport"></span>`; break;
                default: result = `Level <span class="lol-current-level lol-current-level-sport"></span>`; break;
            }

            return result;
        }

        switch (languageCode) {
            case "eng": result = `Level <span class="lol-current-level"></span>`; break;
            // case "ara": result = `المستوى <span class="lol-current-level"></span>`; break;
            case "bul": result = `Ниво <span class="lol-current-level"></span>`; break;
            case "chi": result = `<span class="lol-current-level"></span> 级`; break;
            case "cze": result = `Úroveň <span class="lol-current-level"></span>`; break;
            case "dan": result = `Niveau <span class="lol-current-level"></span>`; break;
            case "ger": result = `Stufe <span class="lol-current-level"></span>`; break;
            case "gre": result = `Επίπεδο <span class="lol-current-level"></span>`; break;
            case "spa": result = `Nivel <span class="lol-current-level"></span>`; break;
            case "fin": result = `Taso <span class="lol-current-level"></span>`; break;
            case "fre": result = `Niveau <span class="lol-current-level"></span>`; break;
            case "hun": result = `<span class="lol-current-level"></span>. szint`; break;
            case "ita": result = `Livello <span class="lol-current-level"></span>`; break;
            case "jpn": result = `レベル <span class="lol-current-level"></span>`; break;
            case "nor": result = `Nivå <span class="lol-current-level"></span>`; break;
            case "dut": result = `Niveau <span class="lol-current-level"></span>`; break;
            case "pol": result = `Poziom <span class="lol-current-level"></span>`; break;
            case "por": result = `Nível <span class="lol-current-level"></span>`; break;
            case "rum": result = `Nivel <span class="lol-current-level"></span>`; break;
            case "rus": result = `Уровень <span class="lol-current-level"></span>`; break;
            case "swe": result = `Nivå <span class="lol-current-level"></span>`; break;
            case "tha": result = `ระดับ <span class="lol-current-level"></span>`; break;
            default: result = `Level <span class="lol-current-level"></span>`; break;
        }

        return result;
    }

    static getErrorMessage(languageCode: string): string {
        let result: string;
        switch (languageCode) {
            case "eng": result = "Error in loading loyalty level"; break;
            // case "ara": result = "حدث خطأ لدى محاولة عرض مستوى الولاء"; break;
            case "bul": result = "Грешка при зареждането на ниво на лоялност"; break;
            case "chi": result = "加载您的忠诚度级别时出错"; break;
            case "cze": result = "Chyba při načítání věrnostní úrovně"; break;
            case "dan": result = "Fejl ved indlæsning af loyalitetsniveau"; break;
            case "ger": result = "Fehler beim Laden der Loyalty-Stufen"; break;
            case "gre": result = "Σφάλμα κατά τη φόρτωση του επιπέδου πιστότητας"; break;
            case "spa": result = "Error al cargar el nivel de fidelidad"; break;
            case "fin": result = "Virhe ladattaessa uskollisuustasoa"; break;
            case "fre": result = "Erreur lors du chargement du niveau de fidélité"; break;
            case "hun": result = "Hiba lépett fel a hűség szint betöltésekor"; break;
            case "ita": result = "Si è verificato un errore durante il caricamento del livello fedeltà"; break;
            case "jpn": result = "ロイヤルティレベルが読み込めませんでした"; break;
            case "nor": result = "Feil i lasting av lojalitetsnivå"; break;
            case "dut": result = "Fout bij het laden van het loyaliteitsniveau"; break;
            case "pol": result = "Błąd podczas ładowania poziomu lojalności"; break;
            case "por": result = "Erro ao carregar nível de fidelidade"; break;
            case "rum": result = "Eroare la încărcarea nivelului de loialitate"; break;
            case "rus": result = "При загрузке уровня лояльности произошла ошибка"; break;
            case "swe": result = "Fel vid inläsning av lojalitetsnivå"; break;
            case "tha": result = "เกิดข้อผิดพลาดในการโหลดระดับความภักดี"; break;
            default: result = "Error in loading loyalty level"; break;
        }
        return result;
    }
}