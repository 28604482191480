import { ILogger } from "../Models/ILogger";
import { Logger } from "./Logger";

export interface ILoggerProvider {
    getLogger(className: string)
}

export class LoggerProvider implements ILoggerProvider {
    private _clientLogger: ILogger;
    constructor(clientLogger: ILogger) {
        this._clientLogger = clientLogger;
    }
    getLogger(className: string): Logger {
        return new Logger(this._clientLogger, className);
    }
}