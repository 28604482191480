import { ILogger } from "../Models/ILogger";
import { ILoggerProvider, LoggerProvider } from "./LoggerProvider";
import { monitor } from "../monitoring/monitoring";

export class IndicatorDataService {
    private _currentLevel: number = 0;
    private _requiredLOLPoints: number = 0;
    private _lOLPointsTotalLevelCounter: number;
    private _onUiChange: Function;
    private _onError: Function;
    private _onLevelChanging: Function;
    private _logger: ILogger;

    constructor(
        currentLevel: number,
        requiredLOLPoints: number,
        lOLPointsTotalLevelCounter: number,
        onUiChange: Function,
        onError: Function,
        onLevelChanging: Function,
        loggerProvider: ILoggerProvider) {
        this._onUiChange = onUiChange;
        this._onError = onError;
        this._onLevelChanging = onLevelChanging;
        this._logger = loggerProvider.getLogger("IndicatorDataService");
        this._currentLevel = currentLevel;
        this._requiredLOLPoints = requiredLOLPoints;
        this._lOLPointsTotalLevelCounter = lOLPointsTotalLevelCounter;
    }

    public subscribeToTopics = (triple8Handler: any) => {
        triple8Handler.subscribe(
            "loyalty",
            this.processLoyaltyData,
            this.onConnectionErrorCallback
        );
    }

    public unSubscribeFromTopic = (triple8Handler: any, cid: any) => {
        triple8Handler.unSubscribe(
            "loyalty",
            this.unsubscribeCallback,
            { cid: cid }
        );
    }

    private unsubscribeCallback = () => {
        monitor("IndicatorDataService.unsubscribedSuccessfully");
        this._logger.debug(`Unsubscribed successfully from Loyalty Topic.`);
    }

    private onConnectionErrorCallback = (e: any) => {
        this._logger.error(`in onConnectionErrorCallback. details: ${e}`);
        monitor("ConnectionError", { message: e });
        this._onError();
    }

    private processLoyaltyData = (msgFields: any) => {
        this._logger.debug(`in processLoyaltyData. msgFields: ${msgFields}`);
        console.log("in processLoyaltyData. msgFields received:", msgFields);
        if (msgFields) {
            if (msgFields.LoyaltyPointsTotalLevelCounter != this._lOLPointsTotalLevelCounter || msgFields.LoyaltyLevelId != this._currentLevel) {
                this._logger.debug("lOLPointsTotalLevelCounter updated");
                this._lOLPointsTotalLevelCounter = msgFields.LoyaltyPointsTotalLevelCounter;

                if (msgFields.LoyaltyLevelId) {
                    let newLevel: number = msgFields.LoyaltyLevelId;
                    if (this._currentLevel != newLevel) {
                        this._onLevelChanging();
                        this._currentLevel = newLevel;
                    }
                    if (msgFields.LevelLoyaltyPointsThreshold) {
                        this._requiredLOLPoints = msgFields.LevelLoyaltyPointsThreshold;
                        this.updateLoyaltyBar();
                    }
                } else {
                    this.updateLoyaltyBar();
                }
            } else {
                this._logger.debug("Received the same number of loyalty points for user. Not updating UI.");
            }
        }
    }

    private calculatePercentage = (lOLPointsTotalLevelCounter: number, requiredLOLPoints: number): number => {
        this._logger.debug(`in calculatePercentage. lOLPointsTotalLevelCounter: ${lOLPointsTotalLevelCounter}. requiredLOLPoints: ${requiredLOLPoints}`)
        if (requiredLOLPoints == 0) return 0;
        return Math.round(lOLPointsTotalLevelCounter / requiredLOLPoints * 100);
    }

    public updateLoyaltyBar = ()=> {
        this._logger.debug("updating ui");
        this._onUiChange(this.calculatePercentage(this._lOLPointsTotalLevelCounter, this._requiredLOLPoints), this._currentLevel);
    }

}