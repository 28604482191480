import { log } from "util";
import { Translations } from "./Translations";

export class IndicatorHtml {
    static getIndicatorMarkup(languageCode: string, brandId: number, productPackage: number): string {
        let pokerPackages: number[] = [6, 7, 41, 57, 58, 60, 95, 96, 122, 123, 124, 126, 127, 136, 144, 145, 146];
        let sportPackages: number[] = [9, 62, 63, 64, 65, 112, 113, 114, 115, 116, 117, 118];

        //Poker design
        if (pokerPackages.indexOf(Number(productPackage)) > -1) {
            return `<div class="lol-wrapper lol-box-shadow">
                    <div class="lol-loading-container">
	    	            <div class="lol-loader-bg"></div>
		                <div class="lol-loader"></div>
	                </div>
                    <div class="lol-content lol-content-poker">
                        <div class="table-poker">
                            <div class="cell-poker-width-15">
                                <div class="logo-image-poker"></div>
                            </div>
                            <div class ="cell-poker">
                                <div class="lol-level-title-poker">${Translations.getLevelTitle(languageCode, true, false)}</div>
                                <div class="lol-bar-progress lol-bar-progress-poker">
                                    <div class="lol-bar-progress-fill lol-bar-progress-fill-poker"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="lol-error-container">
                        <div class="lol-error-message">${Translations.getErrorMessage(languageCode)}</div>
                    </div>
                </div>`;
        }
        //Sport design only for IT & RO markets
        if (sportPackages.indexOf(Number(productPackage)) > -1 && (brandId === 31 || brandId == 76)) {
            return `<div class="lol-wrapper">
                    <div class="lol-loading-container">
	    	            <div class="lol-loader-bg"></div>
		                <div class="lol-loader"></div>
	                </div>
                    <div class="lol-content lol-content-sport">
                        <div class="table-sport">
                            <div class="cell-sport-width-15">
                                <div class="logo-image-sport"></div>
                            </div>
                            <div class ="cell-sport">
                                <div class="lol-level-title-sport">${Translations.getLevelTitle(languageCode, false, true)}</div>
                                <div class="lol-bar-progress lol-bar-progress-sport">
                                    <div class="lol-bar-progress-fill lol-bar-progress-fill-sport"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="lol-error-container">
                        <div class="lol-error-message">${Translations.getErrorMessage(languageCode)}</div>
                    </div>
                </div>`;
        }

        return `<div class="lol-wrapper lol-box-shadow">
                <div class="lol-loading-container">
	    	        <div class="lol-loader-bg"></div>
		            <div class="lol-loader"></div>
	            </div>
                <div class="lol-content">
                    <div class="lol-level-title">${Translations.getLevelTitle(languageCode, false, false)}</div>
                    <div class="lol-bar-progress">
                        <div class="lol-bar-progress-fill"></div>
                    </div>
                    <div class="lol-arrow-container">
                        <div class="lol-arrow"></div>
                    </div>
                </div>
                <div class="lol-error-container">
                    <div class="lol-error-message">${Translations.getErrorMessage(languageCode)}</div>
                </div>
            </div>`;
    }
}