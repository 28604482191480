import { Style } from "../Models/Style";
import { LoyaltyIndicationControllerOptions } from "../Models/LoyaltyIndicationControllerOptions";
import { IndicatorHtml } from "./IndicatorHtml";

export class LoyaltyIndicationController {
    _loliContainer: Element;
    _loaderEnabled: boolean;
    constructor(loyaltyIndicationControllerOptions: LoyaltyIndicationControllerOptions) {
        this._loliContainer = loyaltyIndicationControllerOptions.LoliContainer;
        // inject html
        this._loliContainer.innerHTML = IndicatorHtml.getIndicatorMarkup(loyaltyIndicationControllerOptions.LanguageCode, loyaltyIndicationControllerOptions.BrandId, loyaltyIndicationControllerOptions.ProductPackage);
        this.showArrow(loyaltyIndicationControllerOptions.ShowArrow);
        this._loaderEnabled = loyaltyIndicationControllerOptions.LoaderEnabled !== undefined ?
            loyaltyIndicationControllerOptions.LoaderEnabled : true;
        this.updateUserStyle(loyaltyIndicationControllerOptions.UserStyle);
    }
    set progressBar(percentage: number) {
        (<HTMLDivElement>document.querySelector(".lol-bar-progress-fill")).style.width = percentage + "%";
    }

    set level(newLevel: number) {
        (<HTMLDivElement>document.querySelector(".lol-current-level")).innerText = newLevel.toString();
    }
    set showLoader(show: boolean) {
        if (this._loaderEnabled) {
            if (show) {
                (<HTMLDivElement>document.querySelector(".lol-loading-container")).classList.add("lol-show-content");
            } else {
                (<HTMLDivElement>document.querySelector(".lol-loading-container")).classList.remove("lol-show-content");
            }
        }
    }
    set showError(show: boolean) {
        if (show) {
            this.showLoader = false;
            (<HTMLDivElement>document.querySelector(".lol-error-container")).classList.add("lol-show-content");
            (<HTMLDivElement>document.querySelector(".lol-content")).classList.add("lol-hide-content");
        } else {
            (<HTMLDivElement>document.querySelector(".lol-error-container")).classList.remove("lol-show-content");
            (<HTMLDivElement>document.querySelector(".lol-content")).classList.remove("lol-hide-content");
        }
    }
    private updateUserStyle(style: Style): any {
        let shouldLoadFonts: boolean = true;
        if (style !== undefined) {
            if (style.BackgroundColor !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-content")).style.backgroundColor = style.BackgroundColor;
                (<HTMLDivElement>document.querySelector(".lol-error-container")).style.backgroundColor = style.BackgroundColor;
            }
            if (style.TextColor !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-level-title")).style.color = style.TextColor;
                (<HTMLDivElement>document.querySelector(".lol-error-message")).style.color = style.TextColor;
            }
            if (style.Font !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-wrapper")).style.fontFamily = style.Font;
                shouldLoadFonts = false;
            }
            if (style.FontSize !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-level-title")).style.fontSize = style.FontSize + "px";
                (<HTMLDivElement>document.querySelector(".lol-error-container")).style.fontSize = style.FontSize + "px";
            }
            if (style.ProgressBarColor !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-bar-progress-fill")).style.backgroundColor = style.ProgressBarColor;            
            }
            if (style.ArrowColor !== undefined && document.querySelector(".lol-arrow") !== null) {
                (<HTMLDivElement>document.querySelector(".lol-arrow")).style.borderColor = style.ArrowColor;
            }
            if (style.ShowUnderline !== undefined) {
                if (style.ShowUnderline) {
                    (<HTMLDivElement>document.querySelector(".lol-level-title")).style.textDecoration = "underline";                
                }
                else {
                    (<HTMLDivElement>document.querySelector(".lol-level-title")).style.textDecoration = "none";
                }
            }
            if (style.ShowBoxShadow !== undefined) {
                if (style.ShowBoxShadow) {
                    (<HTMLDivElement>document.querySelector(".lol-wrapper")).classList.add("lol-box-shadow");
                }
                else {
                    (<HTMLDivElement>document.querySelector(".lol-wrapper")).classList.remove("lol-box-shadow");
                }
            }
            if (style.ArrowSize !== undefined && document.querySelector(".lol-arrow") !== null) {
                (<HTMLDivElement>document.querySelector(".lol-arrow")).style.width = style.ArrowSize + "px";
                (<HTMLDivElement>document.querySelector(".lol-arrow")).style.height = style.ArrowSize + "px";
            }
            if (style.ProgressBarHeight !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-bar-progress")).style.height = style.ProgressBarHeight + "px";
                (<HTMLDivElement>document.querySelector(".lol-bar-progress-fill")).style.height = style.ProgressBarHeight + "px";
            }
            if (style.ProgressBarBackgroundColor !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-bar-progress")).style.backgroundColor = style.ProgressBarBackgroundColor;
            }
            if (style.ProgressBarBorderRadius !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-bar-progress")).style.borderRadius  = style.ProgressBarBorderRadius + "px";
                (<HTMLDivElement>document.querySelector(".lol-bar-progress-fill")).style.borderRadius = style.ProgressBarBorderRadius + "px";
            }
            if (style.LOLContentPaddingLeft !== undefined) {
                (<HTMLDivElement>document.querySelector(".lol-content")).style.paddingLeft  = style.LOLContentPaddingLeft;
            }
            if (style.LOLArrowTop !== undefined && document.querySelector(".lol-arrow") !== null) {
                (<HTMLDivElement>document.querySelector(".lol-arrow")).style.top  = style.LOLArrowTop;
            }
  
            if (style.LOLArrowContainerRight !== undefined && document.querySelector(".lol-arrow-container") !== null) {
                (<HTMLDivElement>document.querySelector(".lol-arrow-container")).style.right  = style.LOLArrowContainerRight;
            }
        }
        if (shouldLoadFonts) {
            require("./../../styles/fonts.css");
        }
    }
    private showArrow(show: boolean): void {
        if (show !== undefined && document.querySelector(".lol-arrow") !== null) {
            (<HTMLDivElement>document.querySelector(".lol-arrow")).style.display = show ? "" : "none";
        }
    }
}