import axios from "axios";
import { Errors } from "./Errors";
import { setContext, monitor} from "../monitoring/monitoring"
var jwtDecode = require('jwt-decode');


//let _logger = createLogger("autologin");
let _baseUrl = "/";
let jwtToken = null;

function setAuthenticationApiPath(baseUrl) {
    _baseUrl = baseUrl;
}

async function authenticate(context) {
    return axios.post(`${_baseUrl}/api/token/validate/`, context).
        then(function (response) {
            jwtToken = response.data.token;

            let claims = jwtDecode(jwtToken);
            let userData = null;

            if (typeof claims["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"] === "string") {
                userData = JSON.parse(claims["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]);
            }

            return { success: response.data.success, userData: userData };
        })
        .catch(function (error) {
            //_logger.error("verifyTokenAsync", error);
            throw (Errors.InvalidToken);
        });
}

/*function addAuthHeaders(headers) {

    let added = false;

    if (jwtToken !== null) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
        added = true;
    }

    return added;
}*/

function getBearerToken() {
    return jwtToken;
}

export { authenticate, setAuthenticationApiPath, /*addAuthHeaders,*/ getBearerToken};