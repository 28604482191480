import axios, { AxiosInstance } from "axios";
import { getAppConfig } from "../Config";

export default class BackendService {
    axiosInstance: AxiosInstance;

    constructor() {
        let config = getAppConfig();
        let { loyaltyIndicationHost } = config;
        this.axiosInstance = axios.create({
            baseURL: loyaltyIndicationHost
        });
    }
}