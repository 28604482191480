/* global __webpack_public_path__ */
/* exported __webpack_public_path__ */

declare var __webpack_public_path__ :string;
interface String {
    endsWith(searchString: string, endPosition?: number): boolean;
};

let getAppRootPath = () => {
    let scripts = document.querySelectorAll("script");
    let appScript = null;
    if (scripts != null) {
        for (let i = 0; i < scripts.length; ++i) {
            let script = scripts[i];
            if (script.src != null && script.src.toLowerCase().endsWith("loyaltyindicationsdk.js")) {
                appScript = script.src.replace(/js\/loyaltyindicationsdk.js/ig, '');
                break;
            }
        }
    }
    if (appScript == null) {

    }

    return appScript;
};

// eslint-disable-next-line
__webpack_public_path__ = getAppRootPath();