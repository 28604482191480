import { ILogger } from "../Models/ILogger";

export class Logger {
    private _clientLogger: ILogger;
    private _className: string;
    private _appName = "Loyalty Indication: "
    constructor(clientLogger: ILogger, className: string) {
        this._className = className;
        this._clientLogger = clientLogger;
    }

    debug(text: string): void {
        if (this._clientLogger !== undefined) {
            text = this.getLogText(text, "Debug");
            this._clientLogger.debug(text);
        }
    }

    log(text: string) {
        if (this._clientLogger !== undefined) {
            text = this.getLogText(text, "Info");
            this._clientLogger.log(text);
        }
    }
    warn(text: string) {
        if (this._clientLogger !== undefined) {
            text = this.getLogText(text, "Warning");
            this._clientLogger.warn(text);
        }
    }
    error(text: string) {
        if (this._clientLogger !== undefined) {
            text = this.getLogText(text, "Error");
            this._clientLogger.error(text);
        }
    }

    private getLogText(text: string, messageLevel) {
        return `${this._appName}${this._className} : ${messageLevel} : ${text}`;
    }
}