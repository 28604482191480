import { getBearerToken } from './AuthenticationService';
import BackendService from "./BackendService";


export default class LoyaltyService extends BackendService{
    constructor() {
        super();
        let bearerToken = getBearerToken();
        this.axiosInstance.interceptors.request.use(config => {
            if (bearerToken != null) {
                config.headers.Authorization = `Bearer ${bearerToken}`;
            }

            return config;
        }, err => Promise.reject(err));
    }

    GetTriple8Token = (): Promise<string> => {
        return this.axiosInstance.get('/api/Loyalty/getTriple8Token');
    }

    GetLoyaltyData = (cid:number): Promise<any> => {
        return this.axiosInstance.get('/api/Loyalty/', { params: { cid: cid } });
    }
}