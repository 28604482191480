import { LoyaltyIndicationController } from "./Ui/LoyaltyIndicationController";
import { Events } from "./BusinessLogic/Events";
import { LoyaltyIndicationOptions } from "./Models/LoyaltyIndicationOptions";
import { Errors } from "./BusinessLogic/Errors";
import { IndicatorDataService } from "./BusinessLogic/IndicatorDataService";
import { Logger } from "./BusinessLogic/Logger";
import { ILoggerProvider, LoggerProvider } from "./BusinessLogic/LoggerProvider"
import LoyaltyService from "./BusinessLogic/LoyaltyService";
import { setAuthenticationApiPath, authenticate } from './BusinessLogic/AuthenticationService';
import { loadConfigAsync, getAppConfig } from "./Config";
import "./PublicPath";
import { monitor, setContext } from "./monitoring/monitoring";
import "./../styles/shared.css";
import { userInfo } from "os";


export declare class diffusion {
};

declare class triple8Messaging {
    public static create(token: string, context?: Record<string, string>): any;
}

const LoadingTimeThresholdInSec = 7;
const RetryGettingDiffusionSdk = 3;
declare var __webpack_public_path__: string;

export class LoyaltyIndication {
    private _loliController: LoyaltyIndicationController;
    private _indicatorDataService: IndicatorDataService;
    private _loggerProvider: ILoggerProvider;
    private _logger: Logger;
    private _events: Events;
    private _thresholdTimer: number;
    private _numberOfTrialsGettingDiffusionSdk: number = 0;
    private _onloadTriggered: boolean = false;
    private _initOptions: LoyaltyIndicationOptions;
    private _triple8MessagingUrl: string = "";
    private _loliContainer: Element;
    private Triple8Handler;

    constructor(initOptions: LoyaltyIndicationOptions, onLoad: Function, onError: Function) {
        this._loggerProvider = new LoggerProvider(initOptions.Logger);
        this._logger = this._loggerProvider.getLogger("LoyaltyIndication");
        this._logger.log("Initializing LoyaltyIndication");
        this._events = new Events(onError, onLoad);
        this._initOptions = initOptions;

        loadConfigAsync(__webpack_public_path__).then(() => {
            let config = getAppConfig();
            let { loyaltyIndicationHost, triple8MessagingUrl } = config;
            this._triple8MessagingUrl = triple8MessagingUrl;

            this._loliContainer = document.querySelector("#rl-loyalty-indication");
            if (this._loliContainer === null) {
                this._logger.debug("Input is invalid");
                this._events.onerror(Errors.MissingLoliContainer);
                return;
            }
            if (this.validateInput(initOptions)) {
                //set base path
                setAuthenticationApiPath(loyaltyIndicationHost);

                let { LanguageCode, UserInfo, CID, ClientContext, FirebaseToken } = initOptions;

                let elasticLoggingContext = { cid: CID, language: LanguageCode, ...ClientContext }
                setContext(elasticLoggingContext);

                this._logger.debug("Input is valid");
                this._logger.debug("rl-loyalty-indication was found");
                this._loliContainer.addEventListener("click", this._events.onloyaltydetailsclick);

                // update ui
                this.resetController(initOptions, this._loliContainer);

                this._loliController.showLoader = true;
                this._thresholdTimer = window.setTimeout(() => {
                    this._logger.log("Timeout threshold has passed. Showing error message");
                    this._loliController.showError = true;
                    this._events.onerror(Errors.Timeout);
                },
                    LoadingTimeThresholdInSec * 1000);

                this.displayLoyaltyData(UserInfo, FirebaseToken);
            } else {
                this.resetController(initOptions, this._loliContainer);
                this._events.onerror(Errors.Invalidinput);
                monitor("Authentication Error", { errorCode: Errors.Invalidinput, message: "Invalid input"})
                this._loliController.showError = true;
            }
        });
    }

    private getLoyaltyData = () => {
        let { LanguageCode, ClientContext, UserInfo } = this._initOptions;
        let { BrandId, ClientVersion, ProductPackage, SubBrandId, ClientPlatform } = ClientContext;
        this._logger.debug("Retrieving loyalty data from platform started.");
        let userLoyaltyData = authenticate({
            userInfo: UserInfo,
            brandId: BrandId,
            subBrandId: SubBrandId,
            productPackage: ProductPackage,
            systemId: 7,
            lang: LanguageCode,
            clientPlatform: ClientPlatform,
            clientVersion: ClientVersion
        })
            .then(() => new LoyaltyService()
                .GetLoyaltyData(this._initOptions.CID)).then((loyaltyInfo: any) => { return loyaltyInfo.data });
        return userLoyaltyData;
    }

    private displayLoyaltyData = (userInfo: string, firebaseToken: string) => {
        this.getLoyaltyData().then((loyaltyData:any) => {
            this._indicatorDataService = new IndicatorDataService(
                loyaltyData.currentLevelId,
                loyaltyData.requiredLOLPoints,
                loyaltyData.lolPointsTotalLevelCounter,
                this.onUiChange,
                this.onTriple8MessagingConnectError,
                this.onLevelChanging,
                this._loggerProvider
            );
            this._indicatorDataService.updateLoyaltyBar();

            this.initTriple8Logic(userInfo, firebaseToken);
        });
    }

    private initTriple8Logic = (userInfo: string, firebaseToken: string) => {

        let firebaseTokenData = new Promise<string>((resolve) => {
            resolve(firebaseToken);
        });
        if (firebaseToken == undefined || firebaseToken == "") {
            let { LanguageCode, ClientContext } = this._initOptions;
            let { BrandId, ClientVersion, ProductPackage, SubBrandId, ClientPlatform } = ClientContext;
            this._logger.debug("FirebaseToken not received from client. Retrieving it from platform started.");
            firebaseTokenData = authenticate({
                userInfo: userInfo,
                //userInfo: "Qua5Gsy4dxzwIKeWWwGdBfK0OBtlcCUP", @* Mirage, Brand 0, abc6000/!@#4QWEr *@
                //userInfo: "Jvcg5MqqRoeW16vuh9DVPTpSEA/cJv6u", // Mirage, Brand 1, newtest201/123$qweR
                brandId: BrandId,
                subBrandId: SubBrandId,
                productPackage: ProductPackage,
                systemId: 7,
                lang: LanguageCode,
                clientPlatform: ClientPlatform,
                clientVersion: ClientVersion
            })
                .then(() => new LoyaltyService()
                    .GetTriple8Token()).then((token: any) => { return token.data});
        }
        firebaseTokenData.then(((tokenData: any) => {
            this.verifyTriple8MessagingExists(this._triple8MessagingUrl,
                () => {
                    triple8Messaging
                        .create(tokenData, { "App":"LoyaltyIndication"})
                        .then(
                            async triple8Handler => {
                                this.Triple8Handler = triple8Handler;
                                this._indicatorDataService.subscribeToTopics(triple8Handler);
                            },
                            triple8handler => {
                                this.onTriple8MessagingConnectError("failed connecting to triple8messaging library");
                            }
                        )
                        .catch(error => {
                            this.onLoyaltyError(error);
                        });
                },
                () => {
                    // error loading sdk (after retries)
                    //this._events.onerror(Errors.Timeout)
                    //this._loliController.showError = true;
                    throw (Errors.Timeout);
                });
        }) as any)
            .catch((err) => {
                this.resetController(this._initOptions, this._loliContainer);
                this._events.onerror(err);
                monitor("Authentication Error", { errorCode: err, message: "Invalid token" });
                this._loliController.showError = true;
            });
    }
    private resetController = (initOptions: LoyaltyIndicationOptions, loliContainer: Element) => {
        let { LanguageCode, Style, ShowArrow, EnableLoader, ClientContext } = initOptions;

        this._loliController = new LoyaltyIndicationController({
            LoliContainer: loliContainer,
            LanguageCode: LanguageCode !== undefined ? LanguageCode.toLowerCase() : "eng",
            UserStyle: Style,
            ShowArrow: ShowArrow,
            LoaderEnabled: EnableLoader,
            ProductPackage: ClientContext.ProductPackage,
            BrandId: ClientContext.BrandId
        });

    }

    addEventListener(type: string, listener: Function): void {
        this._events.addEventListener(type, listener);
    }

    private onUiChange = (percentage: number, level: number): void => {
        this._logger.debug(`UI change. percentage: ${percentage}. level: ${level}`);

        clearTimeout(this._thresholdTimer);
        this._loliController.showLoader = false;
        this._loliController.level = level;
        this._loliController.progressBar = percentage;
        if (!this._onloadTriggered) {
            this._onloadTriggered = true;
            this._events.onload();
        }
    }

    private onLevelChanging = (): void => {
        this._loliController.showLoader = true;
    }

    private onTriple8MessagingConnectError = (messageId): void => {
        this._logger.debug("Triple8Messaging connection error:" + messageId);
        monitor("Triple8Messaging error: Connection could not be established");
        this._events.onerror(Errors.InvalidToken);
    }

    private onLoyaltyError = (messageId): void => {
        this._logger.debug("Loyalty generic error:" + messageId);
        monitor("Loyalty generic error: " + messageId);
        this._events.onerror(Errors.InvalidToken);
    }

    private validateInput(initOptions: LoyaltyIndicationOptions): boolean {
        if (initOptions.UserInfo === undefined ||
            initOptions.CID === undefined ||
            initOptions.LanguageCode === undefined ||
            initOptions.ClientContext === undefined ||
            initOptions.ClientContext.BrandId === undefined ||
            initOptions.ClientContext.ClientVersion === undefined ||
            initOptions.ClientContext.ProductPackage === undefined ||
            initOptions.ClientContext.SubBrandId === undefined ||
            initOptions.ClientContext.ClientPlatform === undefined) {
            return false;
        }
        return true;
    }

    private verifyTriple8MessagingExists = (url, onsuccess, onerror) => {
        if (typeof triple8Messaging == "undefined") {
            this._logger.debug("triple8Messaging sdk is missing");
            if (this._numberOfTrialsGettingDiffusionSdk < RetryGettingDiffusionSdk) {
                this._numberOfTrialsGettingDiffusionSdk++;
                this._logger.debug(`Trying to get triple8messaging. Attempt #${this._numberOfTrialsGettingDiffusionSdk}`);
                this.getScript(url, this._numberOfTrialsGettingDiffusionSdk, onsuccess, onerror);
            } else {
                this._logger.debug("retries connecting to triple8messaging has maxed out");
                onerror();
            }
        } else {
            this._logger.debug("triple8messaging exists");
            onsuccess();
        }
    }

    private getScript = (url, trial, onsuccess, onerror) => {
        let js, rljs = document.getElementsByTagName('script')[0];
        let scriptTagId = 'rl-triple8-sdk' + trial;
        js = document.createElement('script');
        js.id = scriptTagId;
        js.src = url;
        rljs.parentNode.insertBefore(js, rljs);
        js.onload = () => {
            this._logger.debug("triple8messaging sdk was fetched");
            onsuccess();
        };
        js.onerror = (e) => {
            this._logger.debug(`failed getting triple8messaging sdk: ${JSON.stringify(e)} `);
            this.verifyTriple8MessagingExists(url, onsuccess, onerror);
        }
    };
    private unsubscribe(): void {
        if (this.Triple8Handler != null && this._initOptions && this._initOptions.CID) {
            this._indicatorDataService.unSubscribeFromTopic(this.Triple8Handler, this._initOptions.CID);
        }
    }

    Terminate = (): void => {
        monitor("IndicatorDataService.disconnect");
        this.unsubscribe();
    }

    UpdateToken = (userInfo: string, firebaseToken = ""): void => {
        if (!this._indicatorDataService) {
            this._indicatorDataService = new IndicatorDataService(
                0,
                0,
                0,
                this.onUiChange,
                this.onTriple8MessagingConnectError,
                this.onLevelChanging,
                this._loggerProvider
            );
        }
        this._logger.debug("Received a new user token. Unsubscribe from Triple8 topics.");
        this.unsubscribe();
        this._logger.debug("Received a new user token. Reinit connection to Triple8.");
        monitor("IndicatorDataService.updateToken");
        this.initTriple8Logic(userInfo, firebaseToken);
    }
}