import axios from "axios";
//import { createLogger } from "@888webteam/logger-core";

//let _logger = createLogger("Config");
let _isConfigLoaded = false;

interface IConfig {
    baseUrl: string;
    loyaltyIndicationHost?: string;
    triple8MessagingUrl?: string;
    clickStreamUrl?: string;

}

let _config: IConfig = {
    baseUrl: ""
};

async function loadConfigAsync(baseUrl: string) {
    
    if (_isConfigLoaded) {
        return;
    }

    try {
        _config.baseUrl = baseUrl;
        let response = await axios.get<IConfig>(`${baseUrl}api/config/`);

        if (response.data) {
            _config = { ..._config, ...response.data };
            _isConfigLoaded = true;
        }
    } catch (error) {
        //_logger.error("loadConfigAsync", error);
    }
}

function getAppConfig(): IConfig {
    return _config;
}

export { getAppConfig, loadConfigAsync };
