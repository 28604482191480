﻿import { ElasticMonitoring } from "./ElasticMonitoring";
import { getAppConfig } from '../Config';

let _state = null;
let _context = null;

let _eventData = {};

let _elasticMonitoring = null;

function setContext(context) {

    if (context !== null) {

        _elasticMonitoring = new ElasticMonitoring(`${__webpack_public_path__}api/loyalty/event/`, "web-loyaltyindication");

        _context = Object.assign(_context || {}, context);

        _elasticMonitoring.setClientLanguage(context.language);
        _elasticMonitoring.setClientVersion(context.ClientVersion);
        _elasticMonitoring.setSubBrandId(context.SubBrandId);
        _elasticMonitoring.setBrandId(context.BrandId);
        _elasticMonitoring.setCid(context.cid);
        _elasticMonitoring.setProductPackage(110);
        _elasticMonitoring.setParentProductPackage(context.ProductPackage);
        _elasticMonitoring.setSystemId(context.SistemId);

        if (typeof context.sessionId === "string") {
            _elasticMonitoring.setCustomHeaders({ "LI-SessionId": context.sessionId });
        }
    }
}

function monitor(eventName, eventProps) {

    try {

        if (!_eventData) {
            _eventData = {};
        }

        let eventData = Object.assign({}, _eventData);

        if (typeof eventProps === "object") {
            eventData = Object.assign(eventData, eventProps);
        }

        if (_state !== null) {
            eventData.state = _state;
        }

        //if (_context !== null) {
        //    eventData.context = _context;
        //}

        if (_elasticMonitoring) {
            _elasticMonitoring.reportInfo(eventName, eventData);
        }
    }
    catch (e) {
        // ignore
    }
}

export { monitor, setContext };