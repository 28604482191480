export class Events {
    private _onloadFn: Function;
    private _onloyaltydetailsclickFn: Function;
    private _ondisconnectFn: Function;
    private _onerrorFn: Function;

    constructor(onError: Function, onLoad:Function) {
        this._onerrorFn = onError;
        this._onloadFn = onLoad;
    }

    addEventListener(type: string, listener: Function): void {
        switch (type) {
            case "onload": this._onloadFn = listener; break;
            case "onloyaltydetailsclick": this._onloyaltydetailsclickFn = listener; break;
            case "ondisconnect": this._ondisconnectFn = listener; break;
        }
    }

    onload = (): void => {
        if (this._onloadFn) {
            this._onloadFn();
        }
    }

    onloyaltydetailsclick = (): void => {
        if (this._onloyaltydetailsclickFn) {
            this._onloyaltydetailsclickFn();
        }
    }

    ondisconnect = (): void => {
        if (this._ondisconnectFn) {
            this._ondisconnectFn();
        }
    }

    onerror = (errorCode: number): void => {
        if (this._onerrorFn) {
            this._onerrorFn({ errorCode: errorCode });
        }
    }
}